import { useRejectUserMutation } from "@/graphql/types";
import gql from "graphql-tag";

gql`
  mutation rejectUser($id: ID!) {
    rejectUser(userId: $id)
  }
`;

export default function () {
  const mutation = useRejectUserMutation({});

  function call(userId: string) {
    return mutation.mutate({
      id: userId,
    });
  }
  return {
    ...mutation,
    call,
  };
}
