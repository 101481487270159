import { useRemoveUserRoleMutation } from "@/graphql/types";
import gql from "graphql-tag";

gql`
  mutation RemoveUserRole($input: RemoveUserRoleInputType!) {
    removeUserRole(input: $input)
  }
`;
export default function () {
  const mutation = useRemoveUserRoleMutation({});
  function call(roleId: string, userId: string, organizationId: string) {
    return mutation.mutate({
      input: {
        roleId: roleId,
        userId: userId,
        organizationId: organizationId,
      },
    });
  }
  return {
    ...mutation,
    call,
  };
}
