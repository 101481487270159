
import { VDPopupListboxClass } from "@/components/pickers/VDPopupListbox";
import VDPopupListbox from "@/components/pickers/VDPopupListbox.vue";
import {
  GetRolesForUserQuery,
  RoleAssigneeType,
  UserRoleType,
  useGetRolesForUserQuery,
} from "@/graphql/types";
import GroupedItems from "@/models/GroupedItems";
import { useResult } from "@vue/apollo-composable";
import gql from "graphql-tag";
import { defineComponent, PropType, Ref, ref, watch, computed } from "vue";
import { useRouter } from "vue-router";

gql`
  query getRolesForUser($id: ID) {
    rolesForUser(userId: $id) {
      ...RoleAssigneeParts
    }
  }
`;

export default defineComponent({
  components: {
    VDPopupListbox,
  },
  emits: ["addedRole"],
  props: {
    addedRoles: {
      type: Array as PropType<UserRoleType[]>,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    userId: {
      type: String,
      default: null,
    },
    organizationId: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    // VDOM
    const listbox = ref<VDPopupListboxClass>();

    // Refs
    const isEnabled = ref(false);

    const router = useRouter();
    const organizationId = router?.currentRoute?.value?.params?.organizationId;

    // Data
    const getRolesResponse = useGetRolesForUserQuery(() => ({
      id: props.userId,
      enabled: isEnabled.value && props.userId !== null,
      fetchPolicy: "network-only",
    }));

    //Computed
    const loading = computed(() => {
      return getRolesResponse.loading.value;
    });

    const error = computed(() => {
      return getRolesResponse.error.value;
    });

    return {
      roles: useGroupedRolesByApplication(
        getRolesResponse.result,
        organizationId.toString()
      ),
      listbox,
      loading,
      error,
      toggle: (event: Event) => {
        isEnabled.value = true;
        listbox.value?.toggle(event);
      },
    };
  },
});

function useGroupedRolesByApplication(
  rolesQuery: Ref<GetRolesForUserQuery | undefined>,
  organizationId: string
) {
  const groupedRoles = ref<GroupedItems<RoleAssigneeType>[]>([]);

  watch(
    () => useResult(rolesQuery).value,
    (newValue) => {
      const map: { [name: string]: RoleAssigneeType[] } = {};

      newValue?.forEach((element) => {
        if (element?.applicationName && map[element.applicationName]) {
          map[element.applicationName].push(element);
        } else if (element?.applicationName) {
          map[element.applicationName] = [element];
        }
      });

      groupedRoles.value = Object.keys(map)
        .sort()
        .map((element) => {
          return {
            name: element,
            items: map[element]
              .sort((a, b) => a.roleName?.localeCompare(b.roleName ?? "") ?? -1)
              .filter((x) => x.organizationId == organizationId),
          };
        })
        .filter((x) => x.items.length > 0);
    },
    { deep: true }
  );

  return groupedRoles;
}
