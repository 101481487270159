import { useAddUserRoleMutation } from "@/graphql/types";
import gql from "graphql-tag";

gql`
  mutation AddUserRole($input: AddUserRoleInputType!) {
    addUserRole(input: $input)
  }
`;

export default function () {
  const mutation = useAddUserRoleMutation({});
  function call(roleId: string, userId: string, organizationId: string) {
    return mutation.mutate({
      input: {
        roleId: roleId,
        userId: userId,
        organizationId: organizationId,
      },
    });
  }
  return {
    ...mutation,
    call,
  };
}
