
import Error from "@/components/Error.vue";
import PrettyBar from "@/components/PrettyBar.vue";
import Sidebar from "@/components/Sidebar.vue";
import useError from "@/composables/useError";
import {
  RoleAssigneeType,
  useGetUserQuery,
  UserRoleType,
  UserType,
} from "@/graphql/types";
import { useResult } from "@vue/apollo-composable";
import gql from "graphql-tag";
import { computed, defineComponent, reactive, ref, watch, toRef } from "vue";
import { onBeforeRouteLeave, useRouter } from "vue-router";
import { required, email } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import useConfirmService from "@/composables/useConfirmService";
import useToastService from "@/composables/useToastService";
import useRandomPassword from "@/composables/useRandomPassword";
import usePasswordValidation from "@/composables/usePasswordValidation";
import { VDPopupListboxClass } from "@/components/pickers/VDPopupListbox";
import useDeleteUser from "./mutations/useDeleteUser";
import useUpdateUser from "./mutations/useUpdateUser";
import RolePicker from "./components/RolePicker.vue";
import useAddUserRole from "./mutations/useAddUserRole";
import useRemoveUserRole from "./mutations/useRemoveUserRole";
import VDButton from "@/components/VDButton.vue";
import { ApprovalStateEnum } from "@/models/Enum";
import FormErrors from "@/forms/FormErrors.vue";
import useRejectUser from "./mutations/useRejectUser";

// Queries
gql`
  query getUser($id: ID) {
    user(userId: $id) {
      ...UserParts
      roles {
        ...UserRoleParts
      }
    }
  }
`;

export default defineComponent({
  props: {
    organizationId: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
  },
  components: {
    PrettyBar,
    Sidebar,
    Error,
    RolePicker,
    VDButton,
    FormErrors,
  },
  data() {
    return {
      optionsAwaitingApproval: [
        {
          label: "Afventer godkendelse",
          value: ApprovalStateEnum.AwaitingApproval,
        },
      ],
    };
  },
  setup(props) {
    // Services
    const confirmService = useConfirmService("sidebar");

    const router = useRouter();

    const toastService = useToastService();

    // VDOM Refs
    const rolePicker = ref<VDPopupListboxClass>();

    // Properties
    const originalUser = ref<UserType>({});

    const user = reactive<UserType>({ roles: [] });

    const hasChanges = computed(
      () =>
        !(
          originalUser.value.phone == user.phone &&
          originalUser.value.email == user.email &&
          originalUser.value.enabled == user.enabled &&
          originalUser.value.approvalState == user.approvalState
        )
    );

    const organizationName = computed(() => user.organization?.name ?? "");
    const organizationCvr = computed(() => user.organization?.cvr ?? "");

    const isApproved = computed(
      () => originalUser.value.approvalState === ApprovalStateEnum.Approved
    );

    const isAwaitingApproval = computed(
      () =>
        originalUser.value.approvalState === ApprovalStateEnum.AwaitingApproval
    );

    const password = ref<string>(useRandomPassword());

    const v$ = useVuelidate(useValidations(), {
      email: toRef(user, "email"),
      password: password,
    });

    const hasConfirmedLeave = ref(false);

    const { result, loading, error } = useGetUserQuery(props, () => ({
      enabled: props.id != "new",
      fetchPolicy: "network-only",
    }));

    const deleteUserMutation = useDeleteUser(props.organizationId, props.id);

    const updateUserMutation = useUpdateUser(
      props.organizationId,
      props.id,
      user
    );

    const addUserRole = useAddUserRole();

    const removeUserRole = useRemoveUserRole();

    const rejectUser = useRejectUser();

    // Functions
    function onAddedRole(role: RoleAssigneeType) {
      function add() {
        user.roles = [
          ...(user.roles ?? []),
          {
            roleId: role.roleId,
            roleName: role.roleName,
            organizationId: role.organizationId,
            organizationName: role.organizationName,
            applicationId: role.applicationId,
            applicationName: role.applicationName,
          },
        ];
      }

      addUserRole
        .call(role.roleId ?? "", props.id, role.organizationId ?? "")
        .then((value) => {
          if (value != null) {
            add();
          }
        });
    }

    function onRemovedRole(role: RoleAssigneeType) {
      function remove() {
        user.roles =
          user.roles?.filter(
            (element) =>
              element?.roleId != role.roleId ||
              element?.organizationId != role.organizationId
          ) ?? [];
      }

      removeUserRole
        .call(role.roleId ?? "", props.id, role.organizationId ?? "")
        .then((value) => {
          if (value != null) remove();
        });
    }

    function onRejectUser() {
      v$.value.$touch();
      if (!v$.value.$error) {
        confirmService.show(
          null,
          "Er du sikker på at du vil afvise brugeren?",
          "Afvis",
          "Fortryd",
          () => {
            rejectUser.call(props.id);
          }
        );
      }
    }

    function onApproveUser() {
      v$.value.$touch();
      if (!v$.value.$error) {
        user.approvalState = ApprovalStateEnum.Approved;
        if ((user.roles ?? []).length === 0) {
          confirmService.show(
            null,
            "Der er ikke tildelt nogen roller til brugeren - det kan altid gøres senere, men det anbefales at blive foretaget samtidig med godkendelsen af brugeren.Vil du oprette brugeren uden samtidigt at tildele roller til brugeren?",
            "Godkend",
            "Fortryd",
            () => {
              updateUserMutation.call();
            }
          );
        } else {
          updateUserMutation.call();
        }
      }
    }

    function onUpdateUser() {
      v$.value.$touch();
      if (!v$.value.$error) {
        updateUserMutation.call();
      }
    }

    updateUserMutation.onDone(() => {
      toastService.show({
        severity: "success",
        title: "Bruger opdateret",
        message: `${user.email} er blevet opdateret`,
      });
      hasConfirmedLeave.value = true;
      router.back();
    });

    deleteUserMutation.onDone(({ errors }) => {
      errors;
      toastService.show({
        severity: "success",
        title: "Bruger slettet",
        message: `${user.email} med ID ${props.id} er blevet slettet!
          `,
      });
      hasConfirmedLeave.value = true;
      router.back();
    });

    rejectUser.onDone(() => {
      toastService.show({
        severity: "success",
        title: "Bruger afvist",
        message: `${user.email} er blevet afvist`,
      });
      hasConfirmedLeave.value = true;
      router.back();
    });

    addUserRole.onDone(() => {
      toastService.show({
        severity: "success",
        title: "Assigning role",
        message: "Role assigned successfully",
      });
    });

    removeUserRole.onDone(() => {
      toastService.show({
        severity: "success",
        title: "Removing role",
        message: "Role removed successfully",
      });
    });

    watch(
      () => useResult(result).value,
      (newValue) => {
        originalUser.value = newValue ?? {};

        Object.assign(user, newValue);
        user.roles = [...(newValue?.roles ?? [])];
      },
      { immediate: true }
    );

    onBeforeRouteLeave(() => {
      if (!hasConfirmedLeave.value && hasChanges.value) {
        return new Promise((resolve) =>
          confirmService.show(
            null,
            "Du har foretaget ændringer der ikke er gemt, vil du stadig gå tilbage?",
            "Ja, gå tilbage",
            "Afbryd",
            () => {
              hasConfirmedLeave.value = true;
              resolve(true);
            },
            () => resolve(false)
          )
        );
      } else {
        return true;
      }
    });

    return {
      rolePicker,
      user,
      password,
      v$,
      error: useError(error),
      loading,
      hasChanges,
      updateUserMutation,
      deleteUserMutation,
      onAddedRole,
      onRemovedRole,
      addRemoveLoading: computed(
        () => addUserRole.loading.value || removeUserRole.loading.value
      ),
      onUpdateUser,
      deleteUser: (event: Event) => {
        confirmService.show(
          event,
          "Er du sikker på at du vil slette brugeren? Denne handling kan ikke fortrydes",
          "Ja, slet",
          "Afbryd",
          () => deleteUserMutation.call()
        );
      },
      isApproved,
      ApprovalStateEnum,
      onRejectUser,
      onApproveUser,
      rejectUser,
      isAwaitingApproval,
      router,
      organizationName,
      organizationCvr,
    };
  },
});

function useValidations() {
  return {
    email: { email, required },
    password: usePasswordValidation(),
  };
}
